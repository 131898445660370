//
// Breadcrumbs
// --------------------------------------------------


.breadcrumb {
  padding: @breadcrumb-padding-vertical @breadcrumb-padding-horizontal;
  margin-bottom: 0;
  list-style: none;
  background-color: @breadcrumb-bg;
  border-radius: @border-radius-base;
  font-family: 'Roboto Slab',serif;

  > li {
    display: inline-block;

    > a {

      color: @text-color;

    }

    + li:before {
      content: "@{breadcrumb-separator}\00a0"; // Unicode space added since inline-block means non-collapsing white-space
      padding: 0 5px;
      color: @breadcrumb-color;
    }
  }

  > .active {
    color: @breadcrumb-active-color;
  }

}
